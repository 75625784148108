const Constants = {
	email: 'email',
	picture: 'picture',
	name: 'given_name',
	loggedIn: 'loggedIn',
	userId: 'userId',
	token: 'token',
	userRole: 'userRole',
}
export default Constants

export const roles = {
	superAdmin: 'ROOT_ADMIN',
	BPS_ADMIN: 'BPS_ADMIN',
	ACE_HIGH_ADMIN: 'ACE_HIGH_ADMIN',
	SEARCHER: 'DATA_ENTRY_SEARCHER',
	VIEWER: 'VIEWER',
	MANAGEMENT_ACCESS: 'MANAGEMENT_ACCESS',
	CONTRACT_REBATES: 'CONTRACT_REBATES',
	APPROVER: 'DATA_ENTRY_APPROVER',
	SI_LEGAL_TEAM: 'SI_LEGAL_TEAM',
	SI_LEGAL_SETTLEMENTS_APPROVER: 'SI_LEGAL_SETTLEMENTS_APPROVER',
	ACCOUNTING: 'ACCOUNTING',
}

export const rolesName = {
	superAdmin: 'ROOT ADMIN',
	BPS_ADMIN: 'BPS ADMIN - SUPPORT',
	ACE_HIGH_ADMIN: 'ACE HIGH ADMIN',
	SEARCHER: 'DATA ENTRY - SEARCHER',
	VIEWER: 'VIEWER',
	MANAGEMENT_ACCESS: 'BPS ADMIN - MANAGEMENT',
	CONTRACT_REBATES: 'CONTRACT REBATES',
	APPROVER: 'DATA ENTRY - APPROVER',
	SI_LEGAL_TEAM: 'SI/LEGAL TEAM',
	SI_LEGAL_SETTLEMENTS_APPROVER: 'SI_LEGAL_SETTLEMENTS_APPROVER',
	ACCOUNTING: 'ACCOUNTING',
}

export const ForMappingUserType = {
	ROOT_ADMIN: 'ROOT ADMIN',
	BPS_ADMIN: 'BPS ADMIN - SUPPORT',
	ACE_HIGH_ADMIN: 'ACE HIGH ADMIN',
	DATA_ENTRY_SEARCHER: 'DATA ENTRY - SEARCHER',
	VIEWER: 'VIEWER',
	MANAGEMENT_ACCESS: 'BPS ADMIN - MANAGEMENT',
	CONTRACT_REBATES: 'CONTRACT REBATES',
	DATA_ENTRY_APPROVER: 'DATA ENTRY - APPROVER',
	SI_LEGAL_TEAM: 'SI/LEGAL TEAM',
	SI_LEGAL_SETTLEMENTS_APPROVER: 'SI_LEGAL_SETTLEMENTS_APPROVER',
	ACCOUNTING: 'ACCOUNTING',
}

export const paymentType = [
	{
		type: 'PTO Delay/Loan Payment Reimb',
		memo: 'Loan Reminbursement',
	},
	{
		type: 'Project Delays',
		memo: 'Customer Satisfaction for delays',
	},
	{
		type: 'Property Damage',
		memo: 'cashing provides release of future claims',
	},
	{
		type: 'Utility Rebate ReimbPre PTO - Customer Satisfaction',
		memo: 'Utility Reimbursement customer satisfaction',
	},
	{
		type: 'Pre PTO - Customer Satisfaction',
		memo: 'Customer Satisfaction ',
	},
	{
		type: 'COGS - Upgrade/Tree Reimb',
		memo: '--------',
	},
	{
		type: 'Post PTO - Appeasement',
		memo: 'Customer Satisfaction ',
	},
	{
		type: 'Post PTO - Production Issues',
		memo: 'cashing provides release of future claims',
	},
]

export const paymentStatus = [
	{
		name: 'Pending',
		value: 'PENDING',
	},
	{
		name: 'Decline',
		value: 'DECLINED',
	},
	{
		name: 'Approve',
		value: 'APPROVED',
	},
	{
		name: 'Processed',
		value: 'PROCESSED',
	},
]

export const paymentStatusName = {
	pending: 'PENDING',
	declined: 'DECLINED',
	approved: 'APPROVED',
	processed: 'PROCESSED',
}

export const patterns = {
	specialCharacterCheckPatternWithSpace: /^[a-z0-9\s_.-]+$/i,
	specialCharacterCheckPattern: /^[a-z0-9_.-]+$/i,
	phoneNumberCheckPattern: /^(?:\d*)$/,
	passwordCheck: /^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/,
	zipCheck: /^[0-9]*$/,
	onlyNumber: /^[0-9]*$/g,
}

export const paginaion = {
	pageSize: 10,
}

export const cities = [
	{
		name: 'Alabama',
		value: 'AL',
	},
	{
		name: 'Alaska',
		value: 'AK',
	},
	{
		name: 'Arizona',
		value: 'AZ',
	},
	{
		name: 'Arkansas',
		value: 'AR',
	},
	{
		name: 'California',
		value: 'CA',
	},
	{
		name: 'Canal Zone',
		value: 'CZ',
	},
	{
		name: 'Colorado',
		value: 'CO',
	},
	{
		name: 'Connecticut',
		value: 'CT',
	},
	{
		name: 'Delaware',
		value: 'DE',
	},
	{
		name: 'District of Columbia',
		value: 'DC',
	},
	{
		name: 'Florida',
		value: 'FL',
	},
	{
		name: 'Georgia',
		value: 'GA',
	},
	{
		name: 'Guam',
		value: 'GU',
	},
	{
		name: 'Hawaii',
		value: 'HI',
	},
	{
		name: 'Idaho',
		value: 'ID',
	},
	{
		name: 'Illinois',
		value: 'IL',
	},
	{
		name: 'Indiana',
		value: 'IN',
	},
	{
		name: 'Iowa',
		value: 'IA',
	},
	{
		name: 'Kansas',
		value: 'KS',
	},
	{
		name: 'Kentucky',
		value: 'KY',
	},
	{
		name: 'Louisiana',
		value: 'LA',
	},
	{
		name: 'Maine',
		value: 'ME',
	},
	{
		name: 'Maryland',
		value: 'MD',
	},
	{
		name: 'Massachusetts',
		value: 'MA',
	},
	{
		name: 'Michigan',
		value: 'MI',
	},
	{
		name: 'Minnesota',
		value: 'MN',
	},
	{
		name: 'Mississippi',
		value: 'MS',
	},
	{
		name: 'Missouri',
		value: 'MO',
	},
	{
		name: 'Montana',
		value: 'MT',
	},
	{
		name: 'Nebraska',
		value: 'NE',
	},
	{
		name: 'Nevada',
		value: 'NV',
	},
	{
		name: 'New Hampshire',
		value: 'NH',
	},
	{
		name: 'New Jersey',
		value: 'NJ',
	},
	{
		name: 'New Mexico',
		value: 'NM',
	},
	{
		name: 'New York',
		value: 'NY',
	},
	{
		name: 'North Carolina',
		value: 'NC',
	},
	{
		name: 'North Dakota',
		value: 'ND',
	},
	{
		name: 'Ohio',
		value: 'OH',
	},
	{
		name: 'Oklahoma',
		value: 'OK',
	},
	{
		name: 'Oregon',
		value: 'OR',
	},
	{
		name: 'Pennsylvania',
		value: 'PA',
	},
	{
		name: 'Puerto Rico',
		value: 'PR',
	},
	{
		name: 'Rhode Island',
		value: 'RI',
	},
	{
		name: 'South Carolina',
		value: 'SC',
	},
	{
		name: 'South Dakota',
		value: 'SD',
	},
	{
		name: 'Tennessee',
		value: 'TN',
	},
	{
		name: 'Texas',
		value: 'TX',
	},
	{
		name: 'Utah',
		value: 'UT',
	},
	{
		name: 'Vermont',
		value: 'VT',
	},
	{
		name: 'Virginia',
		value: 'VA',
	},
	{
		name: 'Virgin Islands',
		value: 'VI',
	},
	{
		name: 'Washington',
		value: 'WA',
	},
	{
		name: 'West Virginia',
		value: 'WV',
	},
	{
		name: 'Wisconsin',
		value: 'WI',
	},
	{
		name: 'Wyoming',
		value: 'WY',
	},
]

export const currentPath = {
	fundOrder: '/fundorder',
	fundReport: '/fundreport',
}

export const rewardTypes = {
	physical: 'visareward',
	virtual: 'virtualvisa',
	check: 'check',
}

export const displayRewardTypeName = {
	PHYSICAL_ALIAS: 'Physical Visa Reward Card',
	VIRTUAL_ALIAS: 'Virtual Visa Reward Card',
	CHECK_ALIAS: 'Check',
}

export const approvalStatus = {
	approved: 'APPROVED',
	declined: 'DECLINED',
}

export const paymentActionFields = {
	void: 'Payment Voided',
	reissue: 'REISSUED',
}

export const shippingMethodFields = {
	STANDARD_USPS: 'standard-usps',
	UPS: 'ups',
}

export const displayShippingMethod = {
	'standard-usps': 'Standard USPS',
	'ups': 'UPS',
}

export const uploaderRoles = [
	roles.superAdmin,
	roles.ACE_HIGH_ADMIN,
	roles.SEARCHER,
	roles.APPROVER,
	roles.CONTRACT_REBATES,
	roles.SI_LEGAL_TEAM,
	roles.SI_LEGAL_SETTLEMENTS_APPROVER,
]

export const paymentRequestRoles = [
	roles.superAdmin,
	roles.ACE_HIGH_ADMIN,
	roles.SEARCHER,
	roles.APPROVER,
	roles.CONTRACT_REBATES,
	roles.SI_LEGAL_TEAM,
	roles.SI_LEGAL_SETTLEMENTS_APPROVER,
]

export const paymentApprovalRoles = [
	roles.superAdmin,
	roles.ACE_HIGH_ADMIN,
	roles.APPROVER,
	roles.SI_LEGAL_SETTLEMENTS_APPROVER,
]

export const usersSectionRoles = [roles.superAdmin, roles.ACE_HIGH_ADMIN]

export const BPSAdminSectionRoles = [
	roles.superAdmin,
	roles.BPS_ADMIN,
	roles.MANAGEMENT_ACCESS,
]

export const AdminFileUploaderRoles = [roles.superAdmin, roles.ACE_HIGH_ADMIN]

export const PowurAdminFileUploaderRoles = [roles.superAdmin]

export const Above2500unredeemedReport = [
	roles.superAdmin,
	roles.MANAGEMENT_ACCESS,
]
