import React,{ useEffect } from "react"

// Error Boundary Fallback
function ErrorFallback({ error }) {
  // Refresh the page if the error message contains 'chunk'
  console.log(error)
  useEffect(() => {
    if (error?.message?.toLowerCase().includes("chunk")) {
      window.location.reload()
    }
  }, [error])

  return <p>Something went wrong.</p>
}

export default ErrorFallback