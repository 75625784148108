import { createContext, useState } from "react";
import Constants from "../Constants";


const AuthContext = createContext({});
let logIn = localStorage.getItem(Constants.loggedIn)== 'true'? true : false
export const AuthProvider = ({children})=>{
    const [auth, setAuth] = useState({isloggedIn : logIn});


    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;