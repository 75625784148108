import React, { lazy } from 'react'
import './App.css'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink } from '@apollo/client'
import Home from './Components/Home'
import PaymentRequest from './Components/PaymentRequest'

import { GoogleOAuthProvider } from '@react-oauth/google'
import ProtectedRoutes, { AuthorizedUsers } from './Common/ProtectedRoutes'
import { AuthProvider } from './Context/AuthProvider'
import Fund from './Components/fund'
import { roles } from './Constants'
import { Spin, message } from 'antd'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './Components/ErrorFallBack'
import { Suspense } from 'react'
import { Container } from 'react-bootstrap'

const PaymentsFormConfirmation = lazy(() =>
	import('./Components/PaymentsFormConfirmation'),
)
const SearchPayment = lazy(() => import('./Components/SearchPayment'))
const Reports = lazy(() => import('./Components/Reports'))
const PaymentApproval = lazy(() => import('./Components/PaymentApproval'))
const PaymentsForm = lazy(() => import('./Components/PaymentsForm'))
const FundOrder = lazy(() => import('./Components/FundOrder'))
const FundReport = lazy(() => import('./Components/FundReport'))
const Checkreport = lazy(() => import('./Components/Checkreport'))
const EditPayment = lazy(() => import('./Components/EditPayment'))
const ResendEmail = lazy(() => import('./Components/ResendEmail'))
const LogIn = lazy(() => import('./Components/LogIn'))
const ForgotPassword = lazy(() => import('./Components/ForgotPassword'))
const ResetPassword = lazy(() => import('./Components/ResetPassword'))
const AddUser = lazy(() => import('./Components/users/addUserForm'))
const UserTable = lazy(() => import('./Components/users/userTable'))
const AdminFileUploader = lazy(() => import('./Components/AdminFileUploader'))
const PaymentRequestUploader = lazy(() =>
	import('./Components/PaymentRequestUploader'),
)
const PowurAdminUploader = lazy(() => import('./Components/PowurAdminUploader'))

function App() {
	let logOutTime

	const httpLink = createUploadLink({
		uri: process.env.REACT_APP_NODE_URL,
		headers: { 'Apollo-Require-Preflight': 'true' },
	})

	const authLink = setContext((_, { headers }) => {
		// get the authentication token from local storage if it exists
		const token = localStorage.getItem('token')
		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				token: token ? `${token}` : '',
			},
		}
	})
	const wsLink = new GraphQLWsLink(
		createClient({
			url: process.env.REACT_APP_NODE_URL_WSS,
			options: {
				reconnect: true,
			},
		}),
	)

	const errorlink = onError(
		({ graphQLErrors, networkError, operation, forward, response }) => {
			if (graphQLErrors) {
				graphQLErrors.map(({ message, locations, path }) => {
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
					)
					if (message === 'Invalid Token!' || message === 'jwt expired') {
						window.location = '/login'
						localStorage.clear()
					}
				})
			}

			if (networkError) {
				console.log(`[Network error]: ${networkError}`)
				//window.location='/login';
				//localStorage.clear()
			}
		},
	)

	const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink])

	const link = split(
		({ query }) => {
			const { kind, operation } = getMainDefinition(query)
			return kind === 'OperationDefinition' && operation === 'subscription'
		},
		wsLink,
		authLink.concat(httpLinkErrHandling),
	)

	const client = new ApolloClient({
		link: link,
		cache: new InMemoryCache(),
	})

	const setTime = () => {
		logOutTime = setTimeout(() => {
			if (window.location.pathname !== '/login') {
				window.location = '/login'
			}
		}, 720 * 1000)
	}

	const resetTime = () => {
		clearTimeEvent()
		setTime()
	}

	const clearTimeEvent = () => {
		if (logOutTime) {
			clearTimeout(logOutTime)
		}
	}

	const autoLogout = () => {
		let events = ['load', 'mousemove', 'mousedown', 'scroll', 'keypress']
		for (const ev of events) {
			window.addEventListener(ev, (e) => {
				resetTime()
			})
		}
		setTime()
	}

	autoLogout()

	return (
		<Suspense
			fallback={
				<Container className="h-100 d-flex justify-content-center align-items-center">
					<Spin />
				</Container>
			}
		>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<AuthProvider>
					<ApolloProvider client={client}>
						<div className="App">
							<GoogleOAuthProvider
								clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
							>
								<Router>
									<Routes>
										<Route exact path="/login" element={<LogIn />} />
										<Route
											exact
											path="/forgotpassword"
											element={<ForgotPassword />}
										/>
										<Route
											exact
											path="/reset-password/:id"
											element={<ResetPassword />}
										/>
										<Route
											exact
											path="/create-password/:id"
											element={<ResetPassword />}
										/>
										<Route element={<ProtectedRoutes />}>
											<Route
												element={
													<AuthorizedUsers
														role={[roles.superAdmin, roles.ACCOUNTING]}
													/>
												}
											>
												<Route
													exact
													path="/fundorder"
													element={<FundOrder />}
												/>
												<Route
													exact
													path="/fundreport"
													element={<FundReport />}
												/>
												<Route
													exact
													path="/checkreport"
													element={<Checkreport />}
												/>
												<Route
													exact
													path="/powuradminapprovaluploader"
													element={<PowurAdminUploader />}
												/>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[roles.superAdmin, roles.ACE_HIGH_ADMIN]}
													/>
												}
											>
												<Route
													exact
													path="/admin-file"
													element={<AdminFileUploader />}
												></Route>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.BPS_ADMIN,
															roles.superAdmin,
															roles.MANAGEMENT_ACCESS,
														]}
													/>
												}
											>
												<Route
													exact
													path="/editpayment"
													element={<EditPayment />}
												/>
												<Route
													exact
													path="/resendemail"
													element={<ResendEmail />}
												/>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[roles.superAdmin, roles.ACE_HIGH_ADMIN]}
													/>
												}
											>
												<Route exact path="/add-user" element={<AddUser />} />
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.superAdmin,
															roles.ACE_HIGH_ADMIN,
															roles.APPROVER,
															roles.SI_LEGAL_SETTLEMENTS_APPROVER,
														]}
													/>
												}
											>
												<Route
													exact
													path="/paymentapproval"
													element={<PaymentApproval />}
												/>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.MANAGEMENT_ACCESS,
															roles.superAdmin,
															roles.ACE_HIGH_ADMIN,
															roles.SI_LEGAL_SETTLEMENTS_APPROVER,
														]}
													/>
												}
											>
												<Route exact path="/users" element={<UserTable />} />
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.superAdmin,
															roles.SEARCHER,
															roles.APPROVER,
															roles.ACE_HIGH_ADMIN,
															roles.CONTRACT_REBATES,
															roles.SI_LEGAL_TEAM,
															roles.SI_LEGAL_SETTLEMENTS_APPROVER,
														]}
													/>
												}
											>
												<Route
													exact
													path="/payment-form"
													element={<PaymentsForm />}
												/>
												<Route
													exact
													path="/payment-form-confirmation"
													element={<PaymentsFormConfirmation />}
												/>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.VIEWER,
															roles.superAdmin,
															roles.BPS_ADMIN,
															roles.SEARCHER,
															roles.APPROVER,
															roles.ACE_HIGH_ADMIN,
															roles.MANAGEMENT_ACCESS,
															roles.CONTRACT_REBATES,
															roles.SI_LEGAL_TEAM,
															roles.SI_LEGAL_SETTLEMENTS_APPROVER,
															roles.ACCOUNTING,
														]}
													/>
												}
											>
												<Route
													exact
													path="/searchpayment"
													element={<SearchPayment />}
												/>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.superAdmin,
															roles.ACE_HIGH_ADMIN,
															roles.BPS_ADMIN,
															roles.MANAGEMENT_ACCESS,
															roles.SEARCHER,
															roles.APPROVER,
															roles.CONTRACT_REBATES,
															roles.SI_LEGAL_TEAM,
															roles.ACCOUNTING,
														]}
													/>
												}
											>
												<Route
													exact
													path="/:reportsection"
													element={<Reports />}
												/>
											</Route>

											<Route
												element={
													<AuthorizedUsers
														role={[
															roles.ACE_HIGH_ADMIN,
															roles.superAdmin,
															roles.SEARCHER,
															roles.APPROVER,
															roles.CONTRACT_REBATES,
															roles.SI_LEGAL_TEAM,
															roles.SI_LEGAL_SETTLEMENTS_APPROVER,
														]}
													/>
												}
											>
												<Route
													exact
													path="/payment-request"
													element={<PaymentRequestUploader />}
												></Route>
											</Route>

											<Route
												path="*"
												exact
												element={<Navigate to="/searchpayment" replace />}
											/>
										</Route>
									</Routes>
								</Router>
							</GoogleOAuthProvider>
						</div>
					</ApolloProvider>
				</AuthProvider>
			</ErrorBoundary>
		</Suspense>
	)
}
export default App
