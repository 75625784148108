import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone)

export function dateToPST(date){
    
    if( date !== null && date !== undefined && date!=="" ){

        const dateTime = dayjs;
    
        const dateObj = dateTime(date);
        const pstTime = dateObj.tz('America/Los_Angeles');

      const formattedDate = pstTime.format('MM/DD/YYYY');
       
        return(
          formattedDate
        )
    }
    else{
        return "";
    }

}


export function dateWithTimeToPST(date){
    
    if( date !== null && date !== undefined && date!=="" ){

        const dateTime = dayjs;
    
        const dateObj = dateTime(date);
        
        
        const pstTime = dateObj.tz('America/Los_Angeles');
       
    
        const formattedDate = pstTime.format('MM/DD/YYYY HH:mm A');
       
        return(
          formattedDate
        )
    }
    else{
        return "";
    }

}


export function amountFormat(amount) {
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
   if (amount !== null && amount !== undefined) {
     let result;
     if (isNaN(parseFloat(amount))) {
       result = amount;
     } else {
       result = parseFloat(amount).toFixed(2);
     }
     result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

     if (String(result).includes(".")) {
        if(String(result).includes("$")){
            return `${result}`;
        }else{
          return `$${result}`;
        }
     } else {
        if (String(result).includes("$")) {
          return `${result}.00`;
        } else {
          return `$${result}.00`;
        }
     }
   } else {
     return "";
   }

}
