import React, { Fragment } from 'react'
import {
	Nav,
	Navbar,
	NavDropdown,
	Container,
	Image,
	Button,
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Constants, {
	BPSAdminSectionRoles,
	paymentApprovalRoles,
	paymentRequestRoles,
	roles,
	uploaderRoles,
	usersSectionRoles,
	AdminFileUploaderRoles,
	Above2500unredeemedReport,
	PowurAdminFileUploaderRoles,
} from '../Constants'
import './Navigation.css'
import { useNavigate } from 'react-router-dom'
import { googleLogout } from '@react-oauth/google'

const Navigation = () => {
	const userRole = localStorage.getItem(Constants.userRole)
	const redirect = useNavigate()
	const logOut = () => {
		googleLogout()
		localStorage.clear()
		redirect('/login')
	}

	const activeLink = ({ isActive }) => {
		if (isActive) {
			return 'active'
		} else {
			return 'navLink'
		}
	}

	return (
		<>
			<Navbar expand="xl" bg="white">
				<div className="border-bottom container-fluid">
					<Navbar.Brand href="/" className="ms-0 nav-logo">
						<Image fluid src="images/Power Logo.png" className="nav-logo" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse
						id="responsive-navbar-nav"
						className="justify-content-end"
					>
						<Nav className="ms-auto">
							<NavDropdown
								title="Uploader"
								className="border-bottom-0 px-xl-0 ps-4"
								hidden={uploaderRoles.includes(userRole) ? false : true}
							>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/payment-request">
										Payment Request
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink
										className={activeLink}
										to="/admin-file"
										hidden={
											AdminFileUploaderRoles.includes(userRole) ? false : true
										}
									>
										Admin File
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink
										className={activeLink}
										to="/powuradminapprovaluploader"
										hidden={
											PowurAdminFileUploaderRoles.includes(userRole)
												? false
												: true
										}
									>
										Powur Admin Approval Flow File
									</NavLink>
								</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link className="border-bottom-0 px-0">
								<NavLink
									className={activeLink}
									to="/payment-form"
									hidden={paymentRequestRoles.includes(userRole) ? false : true}
								>
									Payment Request
								</NavLink>
							</Nav.Link>
							<Nav.Link className="border-bottom-0 px-0">
								<NavLink className={activeLink} to="/searchpayment ">
									Search Payment
								</NavLink>
							</Nav.Link>

							<NavDropdown
								title="Reports"
								className="mt-0 px-xl-0 ps-4"
								hidden={
									userRole === roles.VIEWER ||
									userRole === roles.ACCOUNTING ||
									userRole === roles.MANAGEMENT_ACCESS
										? true
										: false
								}
							>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/allpayments">
										All payments
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/approvedpayments">
										Approved payments
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/processedpayments">
										Processed payments
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/pendingpayments">
										Pending payments
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/declinedpayments">
										Declined payments
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/upstracking">
										UPS Tracking
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item
									hidden={userRole !== roles.superAdmin ? true : false}
								>
									<NavLink className={activeLink} to="/unredeemedpayments">
										Unredeemed Payments
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item
									hidden={
										Above2500unredeemedReport.includes(userRole) ? false : true
									}
								>
									<NavLink className={activeLink} to="/2500unredeemedpayments">
										$2500 Unredeemed Report
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item hidden={userRole !== roles.superAdmin}>
									<NavLink className={activeLink} to="/prpaymentsreport">
										PR Payments Report
									</NavLink>
								</NavDropdown.Item>
							</NavDropdown>

							{userRole === roles.ACCOUNTING && (
								<NavDropdown title="Reports" className="mt-0 px-xl-0 ps-4">
									<NavDropdown.Item>
										<NavLink className={activeLink} to="/allpayments">
											All payments
										</NavLink>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<NavLink className={activeLink} to="/processedpayments">
											Processed payments
										</NavLink>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<NavLink className={activeLink} to="/upstracking">
											UPS Tracking
										</NavLink>
									</NavDropdown.Item>
								</NavDropdown>
							)}

							<Nav.Link className="border-bottom-0 px-0">
								<NavLink
									className={activeLink}
									to="/paymentapproval"
									hidden={
										paymentApprovalRoles.includes(userRole) ? false : true
									}
								>
									Payment Approval
								</NavLink>
							</Nav.Link>
							<NavDropdown
								title="Users"
								className="mt-0 px-xl-0 ps-4"
								hidden={usersSectionRoles.includes(userRole) ? false : true}
							>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/users">
										Users
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/add-user">
										Add User
									</NavLink>
								</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link className="border-bottom-0 px-0">
								<NavLink
									className={activeLink}
									to="/users"
									hidden={
										userRole !== roles.MANAGEMENT_ACCESS &&
										userRole !== roles.SI_LEGAL_SETTLEMENTS_APPROVER
											? true
											: false
									}
								>
									Users
								</NavLink>
							</Nav.Link>
							<NavDropdown
								title="BPS Admin"
								className={`mt-0 px-xl-0 ps-4`}
								hidden={BPSAdminSectionRoles.includes(userRole) ? false : true}
							>
								<NavDropdown.Item
									hidden={userRole !== roles.superAdmin ? true : false}
								>
									<NavLink className={activeLink} to="/fundorder">
										Invoice Order
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item
									hidden={userRole !== roles.superAdmin ? true : false}
								>
									<NavLink className={activeLink} to="/fundreport">
										Fund Report
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item
									hidden={userRole !== roles.superAdmin ? true : false}
								>
									<NavLink className={activeLink} to="/checkreport">
										Check Report
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/resendemail">
										Resend Email
									</NavLink>
								</NavDropdown.Item>
								<NavDropdown.Item>
									<NavLink className={activeLink} to="/editpayment">
										Edit Payment
									</NavLink>
								</NavDropdown.Item>
							</NavDropdown>

							{userRole === roles.ACCOUNTING && (
								<NavDropdown title="BPS Admin" className={`mt-0 px-xl-0 ps-4`}>
									<NavDropdown.Item>
										<NavLink className={activeLink} to="/fundreport">
											Fund Report
										</NavLink>
									</NavDropdown.Item>
									<NavDropdown.Item>
										<NavLink className={activeLink} to="/checkreport">
											Check Report
										</NavLink>
									</NavDropdown.Item>
								</NavDropdown>
							)}

							<Nav.Link>
								<p onClick={logOut} className=" w-100 text-info">
									Logout
								</p>
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</div>
			</Navbar>
		</>
	)
}

export default Navigation
